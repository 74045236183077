
import { mapGetters } from 'vuex';
import regl from 'regl';
import { shaders } from '../lib/action-card-background';
import uiMixin from '../mixins/ui';

const SHADER = 'starfield';

export default {
  name: 'UserSettings',
  mixins: [
    uiMixin,
  ],
  data() {
    return {
      userId: undefined,
      isSyncingUserDayResetTime: false,
      ratingsBgWidth: 500,
      ratingsBgHeight: 500,
    };
  },
  computed: {
    ...mapGetters('userSettings', [
      'daysCount',
      'journeyStartedAtDay',
    ]),
    ...mapGetters('conditions', [
      'ratings',
      'affinity',
    ]),
    userDayResetTime: {
      get() {
        return this.$dayjs(this.$store.state.userSettings.dayResetTime, 'hh:mm:ss').toDate();
      },
      async set(val) {
        this.isSyncingUserDayResetTime = true;

        await this.$store.dispatch('userSettings/edit', {
          dayResetTime: this.$dayjs(val).format('hh:mm:ss'),
        });

        this.isSyncingUserDayResetTime = false;
      },
    },
    userDayResetTimeDisplay() {
      return this.$dayjs(this.userDayResetTime).format('HH:mm A');
    },
    ratingsLocal() {
      return this.ratings.map(r => ({
        ...r,
        valueRange: [1, r.value + 1].sort(),
        value: r.value * 100,
      }));
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.initShader();
    });

    this.userId = await this.$auth.getUserId();
  },
  beforeDestroy() {
    this.removeShader();
  },
  methods: {
    handleAvatar() {
      this.$store.dispatch('ui/showModal', {
        name: 'userSettingsAvatar',
        payload: {
          onSelect: () => {
            this.$store.dispatch('ui/hideModal', 'userSettingsAvatar');
          },
        },
      });
    },
    handleSubscription() {
      this.$store.dispatch('ui/showModal', { name: 'userSubscriptions' });
    },
    async handleAccountDelete() {
      const isDeleting = await this.uiMsgBoxConfirm('Are you sure you want to delete your account? This action is irreversible!', {
        acceptClass: 'danger',
        acceptLabel: 'Delete my account permanently',
        acceptVariant: 'danger',
      });

      if (isDeleting) {
        try {
          await this.$auth.deleteUser();
          this.uiMsgBoxOk('Account deleted.');
        } catch (e) {
          if (e && e.code === 'auth/requires-recent-login') {
            await this.uiMsgBoxOk('It\'s been a while since you\'ve signed in. For security purposes, please sign in again. You will then be able to delete your account.', {
              okLabel: 'Sign In',
            });

            this.$store.dispatch('ui/showModal', { name: 'auth' });
          } else {
            this.uiMsgBoxOk('We could not delete your account, an error occurred. Pleased try again.');
          }
        }
      }
    },
    initShader() {
      const glCanvas = this.$refs['gl-canvas'];

      if (this._reglInstance || !glCanvas) {
        return;
      }

      this.$dbg('user-settings')('initShader');

      const fragmentShader = shaders[SHADER].frag;
      const start = `// #version 300 es
      precision mediump float;

      uniform vec2 iResolution;
      uniform vec4 iMouse;
      uniform float iTime;
      uniform float rand;
      uniform float importanceMultiplier;
      uniform float completeness;
      `;

      const end = `
      void main() {
        mainImage(gl_FragColor.rgba, gl_FragCoord.xy);
      }
      `;

      this._reglInstance = regl(glCanvas);
      const draw = this._reglInstance({
        // Shaders in regl are just strings.  You can use glslify or whatever you want
        // to define them.  No need to manually create shader objects.
        frag: `${start}${fragmentShader}${end}`,

        vert: `
        precision mediump float;
        attribute vec2 position;
        varying vec2 uv;
        void main () {
          uv = position;
          gl_Position = vec4(2.0 * position - 1.0, 0, 1);
        }`,

        // This tells regl the number of vertices to draw in this command
        count: 3,

        attributes: {
          position: [
            -2, 0,
            0, -2,
            2, 2]
        },

        uniforms: {
          iResolution: [this.ratingsBgWidth, this.ratingsBgHeight],
          iMouse: [0, 0, 0, 0],
          iTime: ({ tick }) => 0.006 * tick,
          rand: this._reglInstance.prop('rand'),
          importanceMultiplier: this._reglInstance.prop('importanceMultiplier'),
          completeness: this._reglInstance.prop('completeness'),
        },
      });

      this._reglInstance.frame(() => {
        // clear contents of the drawing buffer
        this._reglInstance.clear({
          color: [0, 0, 0, 0],
          depth: 1
        })

        draw({
          rand: this.rand,
          importanceMultiplier: 0.5,
          completeness: 1.0,
        });
      });
    },
    removeShader() {
      if (this._reglInstance) {
        this.$dbg('user-settings')('removeShader');
        this._reglInstance.destroy();
      }
    },
  },
};
